import React, { useEffect, useState } from "react";
import {
  getDNAList,
  createDNA,
  updateDNA,
} from "../../../redux/knowYourDNASlice";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Input, Form, Card, Popconfirm } from "antd";
import { Table } from "antd";
import moment from "moment";

function KnowYourDNA() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dna);
  const [editInfo, setEditInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const deleteKnowYourDNA = (item) => {
    console.log(item);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: "URL",
      dataIndex: "url",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      sorter: (a, b) => a.contact.localeCompare(b.contact),
    },
    {
      title: "CreatedBy",
      dataIndex: "createdBy",
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
    },
    {
      title: "UpdatedBy",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy && a.updatedBy.localeCompare(b.updatedBy),
    },
    {
      title: "UpdatedOn",
      dataIndex: "updatedOn",
      sorter: (a, b) => a.updatedOn.localeCompare(b.updatedOn),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      dataIndex: "action",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditInfo(record);
              setIsModalVisible(true);
            }}
          />
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => {
              dispatch(deleteKnowYourDNA(record.id));
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];
  const handleEdit = (item) => {
    setEditInfo(item);
    console.log("row", item);
    setIsModalVisible(true);
  };
  const handleDelete = (item) => {
    console.log("delete", item);
  };
  useEffect(() => {
    dispatch(getDNAList());
  }, []);
  useEffect(() => {
    let _data = [];
    if (data?.dna?.data.length > 0) {
      let temp = JSON.parse(JSON.stringify(data?.dna?.data));
      temp.forEach((item) => {
        item?.data.forEach((_dna) => {
          _dna.createdBy = _dna.createdBy?.email;
          _dna.updatedBy = _dna.updatedBy?.email;
          _dna.createdOn = moment(_dna.createdOn).format("YYYY-MM-DD HH:mm:ss");
          _dna.updatedOn = moment(_dna.updatedOn).format("YYYY-MM-DD HH:mm:ss");
          _dna.url = (
            <a href={_dna.url} target="_blank" rel="noreferrer">
              {_dna.url}
            </a>
          );
          _dna.action = (
            <>
              <Button
                onClick={() => handleEdit(_dna)}
                shape="circle"
                icon={<EditOutlined />}
              />
              <Popconfirm
                title="Delete the targeted therapy?"
                description="Are you sure to delete this targeted therapy?"
                onConfirm={() => handleDelete(_dna)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{ marginLeft: 2 }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </>
          );
          _data.push(_dna);
        });
      });
      console.log("dsadas", _data);

      setTableData(_data);
    }
  }, [data]);
  const handleaddNew = () => {
    setIsModalVisible(false);
    setEditInfo({});
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditInfo({});
  };
  const handleFormSubmission = (data) => {
    if (Object.keys(editInfo).length > 0) {
      let id = editInfo._id;
      dispatch(updateDNA({ ...data, id }));
    } else {
      dispatch(createDNA(data));
    }
    setIsModalVisible(false);
    setEditInfo({});
  };
  return (
    <Card
      title="Know your DNA List"
      extra={
        <Button
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add New
        </Button>
      }
      style={{ margin: 15, maxHeight: "90vh" }}
    >
      <Modal
        title={`${Object.keys(editInfo).length > 0 ? "Edit" : "Add"} `}
        open={isModalVisible}
        onOk={handleaddNew}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="wrap"
          labelCol={{
            flex: "110px",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          style={{
            maxWidth: 600,
          }}
          initialValues={editInfo}
          onFinish={handleFormSubmission}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Category"
            name="category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contact"
            name="contact"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Table
        columns={columns}
        dataSource={tableData}
        // onChange={onChange}
        loading={data?.dna?.loading}
        scroll={{
          x: 1300,
          y: 650,
        }}
        style={{ overflow: "auto" }}
      />
    </Card>
  );
}

export default KnowYourDNA;
