import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import QRCode from "react-qr-code";
import { Spin } from "antd";
import base64 from "base-64";
// const be_url = process.env.API_URL || "http://localhost:5001";
const be_url = "https://sit.cureonco.com";
const socket_path = "/core/qrlogin";
console.log("Socket configs ->", be_url, socket_path);
const socket = io(be_url, { path: socket_path }); // Connect to the '/socket' namespace
function QRLoginScanner(props) {
  const [loading, setLoading] = useState(false);
  const [qrGen, setQrGen] = useState(false);
  const [sec, setSec] = useState(null);
  useEffect(() => {
    connectSocket();
    return () => {
      // connectSocket();
      // socket.disconnect();
    };
  }, [props.show]);
  const connectSocket = () => {
    socket.on("connect", () => {
      setQrGen(false);
      console.log("Connected to server");
      socket.emit("generate-qr", {
        id: Date.now(),
        senderName: socket.id,
      });
    });
    socket.on("qr-data", (data) => {
      console.log("UNIQUE ID", data);
      setSec(JSON.stringify(data));
    });
    socket.on("login-loading", (data) => {
      setLoading(data);
    });
    socket.on("login-success", (data) => {
      console.log("Message From Backend", data);
      let { userid, loginstatus, accessToken, userInformation } = data;
      let user_enc = base64.encode(userid);
      let login_status_enc = base64.encode(loginstatus);
      let accessToken_enc = base64.encode(accessToken);
      let userinfo_enc = base64.encode(JSON.stringify(userInformation));
      localStorage.setItem("CUREONCO_USERINFO", user_enc);
      localStorage.setItem("IS_AUTH", login_status_enc);
      localStorage.setItem("CUREONCO_ACCESSTOKEN", accessToken_enc);
      localStorage.setItem("CUREONCO_USERINFO", userinfo_enc);
      window.location.href = "/uploadfiles";
    });
    socket.on("connect_failed", function () {
      console.log("Connection Failed");
      setQrGen(false);
    });
    socket.on("disconnect", () => {
      setQrGen(false);
      console.log("Disconnected from server");
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {qrGen ? (
          <p>Generating QR Code...</p>
        ) : (
          <Spin spinning={loading} size="large" tip="Loading...">
            <div className="chat-app">
              {props.show && sec && (
                <QRCode level={"M"} fgColor="#0f3a64" value={sec} />
              )}
            </div>
          </Spin>
        )}
      </div>
    </>
  );
}

export default QRLoginScanner;
